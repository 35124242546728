// Here you can add other styles
@media print {
  body * {
    visibility: hidden;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    top: 0px;
    left: 0px;
    width: 100%;
    position: fixed;
  }
}
